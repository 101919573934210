import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Vlog from "../components/vlog/Vlog"

const VlogDetail = ({
  data: { vlog, seo },
  pageContext: { parent, title, language }
}) => {
  return (
    <Layout parent={parent} title={title}>
      <HelmetDatoCms defer={false} seo={vlog.seoMetaTags} language={language} />
      <Vlog {...vlog} />
    </Layout>
  )
}
export const query = graphql`
  query vlogById($id: String) {
    vlog: datoCmsVlogPost(id: { eq: $id }) {
      id
      originalId
      slug
      title
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      showComments
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      url {
        url
        providerUid
      }
      chosenThumbnail {
        gatsbyImageData(imgixParams: { w: "1280", h: "720", fit: "crop" })
      }
      meta {
        publishedAt
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default VlogDetail
