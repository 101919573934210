import React, { useEffect, useState } from "react"
import HeartSvg from "@svgs/heart.svg"
import HeartEmptySvg from "@svgs/heart-empty.svg"
import style from "./favouriteheartbutton.mod.scss"
import { useLocation } from "@reach/router"

const FavouriteHeartButton = ({
  id,
  isActive,
  disabled = false,
  large = false,
  prompt = false,
  modifiers = [],
  onRemoveFavourite,
  onAddFavourite,
  favouriteArray = [],
  isFeatured
}) => {
  const [selected, setSelected] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    const checkIsFavourite = favouriteArray.includes(id)
    isFeatured || checkIsFavourite || pathname === "/account/favourites"
      ? setSelected(true)
      : setSelected(false)
  }, [])

  const favourite = "favourite"
  const unfavourite = "unfavourite"
  large && modifiers.push(style[`fav--large`])

  const handleFavourites = id => {
    if (!selected) {
      onAddFavourite(id)
      setSelected(!selected)
    } else if (
      window.confirm(
        "Are you sure you want to remove this item from your favourites?"
      )
    ) {
      onRemoveFavourite(id)
      setSelected(!selected)
    }
  }

  return (
    <label
      data-is-disabled={disabled}
      data-is-active={isActive}
      className={[style.fav, ...modifiers].join(" ")}
    >
      <input
        disabled={disabled}
        onClick={() => handleFavourites(id)}
        className={style.fav__input}
        type="checkbox"
        checked={selected}
      />
      <span className={style.fav__label}>
        {selected ? favourite : unfavourite}
      </span>
      {selected ? <HeartSvg /> : <HeartEmptySvg />}
    </label>
  )
}

export default FavouriteHeartButton
