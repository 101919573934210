import React from "react"
import Block from "@global/layout/Block"
import FavouriteHeartButton from "@global/cards/FavouriteHeartButton"
import style from "./vlog.mod.scss"
import { formatDate } from "@helpers/dateHelper"
import { useAuth0 } from "@utils/auth"

const Info = ({ meta, title, description, originalId }) => {
  const { addFavouriteVlogs, favouriteVlogs, removeFavouriteVlogs } = useAuth0()
  return (
    <Block padding="top" gutters={true} maxWidth="inner">
      <div className={style.vlog__content}>
        <h1 className={style.vlog__title}>{title}</h1>
        {meta && (
          <p className={style.vlog__date}>{formatDate(meta.publishedAt)}</p>
        )}
        {description && (
          <div
            className={style.vlog__description}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        )}

        <div className={style.vlog__favourite}>
          <FavouriteHeartButton
            id={originalId}
            onAddFavourite={addFavouriteVlogs}
            onRemoveFavourite={removeFavouriteVlogs}
            favouriteArray={favouriteVlogs}
            large={true}
          />
        </div>
      </div>
    </Block>
  )
}

export default Info
