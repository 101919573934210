import React from "react"
import { graphql } from "gatsby"
import Vimeo from "@elements/Vimeo"
import style from "./vlog.mod.scss"
import PropTypes from "prop-types"
import { useAuth0 } from "@utils/auth"
import * as Vlog from "."
import * as FirebaseComments from "../Comments"
import { ProtectedRoute } from "../ProtectedRoute"

const VlogDetail = ({
  id,
  originalId,
  title,
  descriptionNode,
  meta,
  language,
  url,
  showComments = false,
  chosenThumbnail
}) => {
  const { user } = useAuth0()

  const getVlogImage = () => {
    return chosenThumbnail
  }

  return (
    <ProtectedRoute>
      <div className={style.vlog}>
        <div className={style.vlog__hero}>
          <Vimeo videoUrl={url} poster={getVlogImage()} />
        </div>

        <Vlog.Info
          title={title}
          meta={meta}
          description={descriptionNode}
          originalId={originalId}
        />
        {showComments && (
          <Vlog.VlogComments>
            <FirebaseComments.List id={originalId} />
            <FirebaseComments.SubmitHeader
              text="Submit a Comment"
              loggedAs={user?.name}
            />
            <FirebaseComments.SubmitForm id={id} originalId={originalId} />
          </Vlog.VlogComments>
        )}
      </div>
    </ProtectedRoute>
  )
}

export default VlogDetail

VlogDetail.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.shape({
    publishedAt: PropTypes.string
  }).isRequired,
  url: PropTypes.shape({
    url: PropTypes.string,
    providerUid: PropTypes.string
  }).isRequired,
  showComments: PropTypes.bool
}

export const query = graphql`
  fragment Vlog on DatoCmsVlogPost {
    id
    originalId
    slug
    title
    description
    showComments
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    url {
      url
      providerUid
    }
    chosenThumbnail {
      gatsbyImageData(imgixParams: { w: "1280", h: "720", fit: "crop" })
    }
    meta {
      publishedAt
    }
  }
`
